import Header from "../components/Header";
import HeroSection from "../components/HeroSection";
import PrimaryFeatures from "../components/PrimaryFeatures";
import SecondaryFeatures from "../components/SecondaryFeatures";
import TertiaryFeatures from "../components/TertiaryFeatures";
import OthersFeatures from "../components/OthersFeatures";
import CallToAction from "../components/CallToAction";
import Partners from "../components/Partners";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <Header />
      <main>
        <HeroSection />
        <div className="h-full w-full bg-white">
          <PrimaryFeatures />
          <SecondaryFeatures />
          <TertiaryFeatures />
          <OthersFeatures />
          <CallToAction />
          <Partners />
          <Footer />
        </div>
      </main>
    </>
  );
}

export default Home;
