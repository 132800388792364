import { FiArrowDownLeft, FiArrowUpRight } from "react-icons/fi";

function OthersFeatures() {
  return (
    <section className="bg-white h-full md:h-[70vh] flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 w-screen py-5 px-5 md:px-32 font-manrope">
      <div className="px-5 md:px-0 relative h-full w-full bg-gray-200 rounded-2xl flex flex-col items-center justify-center py-16">
        <>
          <img
            className="inset-0 h-32 w-auto absolute left-[4vw]"
            loading="lazy"
            alt=""
            src="/glass7-3@2x.png"
          />
          <img
            className="inset-0 h-32 w-auto absolute left-[60vw] md:left-[28vw] top-[5vh]"
            loading="lazy"
            alt=""
            src="/glass7-3@2x.png"
          />
          <img
            className="inset-0 h-48 w-auto absolute left-[52vw] md:left-[26vw] top-[27vh]"
            loading="lazy"
            alt=""
            src="/glass7-3@2x.png"
          />
        </>
        <span className="mt-16 font-bold text-3xl">F CFA 1,286.00</span>
        <div className="mt-3 flex flex-row space-x-2">
          <div className="bg-black h-1 w-10 rounded-xl"></div>
          <div className="bg-gray-300 h-1 w-5 rounded-xl"></div>
          <div className="bg-gray-300 h-1 w-5 rounded-xl"></div>
        </div>
        <div className="mt-20 flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-10">
          <div className="md:w-40 bg-black text-white font-semibold flex items-center justify-center space-x-2 py-2 px-5 rounded-lg">
            <p>Envoyer</p>
            <FiArrowUpRight className="h-5 w-5" />
          </div>
          <div className="md:w-40 bg-black text-white font-semibold flex items-center justify-center space-x-2 py-2 px-5 rounded-lg">
            <p>Demander</p>
            <FiArrowDownLeft className="h-5 w-5" />
          </div>
        </div>
        <div className="flex flex-col items-start mt-20 space-y-5">
          <h1 className="font-semibold text-3xl max-w-xs">
            Envoyez & Demandez du cash à vos proches
          </h1>
          <p className="max-w-lg">
            Par mobile money ou la banque, transférez de l’argent de façon
            instantannée
          </p>
        </div>
      </div>
      <div className="px-5 md:px-0 relative h-full w-full bg-gray-200 rounded-2xl flex flex-col items-center justify-center py-16">
        <div className="flex flex-col items-start space-y-5">
          <div className="flex items-center justify-center self-center mt-9">
            <img
              className="h-[30.3vh] w-auto translate-x-12"
              loading="lazy"
              alt=""
              src="/credit-card-mockup@2x.png"
            />
            <img
              className="h-[30.3vh] w-auto -translate-x-12"
              loading="lazy"
              alt=""
              src="/credit-card-mockup-1@2x.png"
            />
            <img
              className="inset-0 h-16 w-auto absolute left-[65vw] md:left-[27.5vw] top-[12vh]"
              loading="lazy"
              alt=""
              src="/heart@2x.png"
            />
          </div>
          <h1 className="font-semibold text-3xl max-w-xs">
            Carte cadeau ou de consommation
          </h1>
          <p className="max-w-lg">
            Offrez ou gérez vos bon de consommation, ticket restau ou essence
            avec Cashless
          </p>
        </div>
      </div>
    </section>
  );
}

export default OthersFeatures;
