import { FaArrowRight } from "react-icons/fa6";
import { BiSolidCreditCardAlt, BiSolidCreditCardFront } from "react-icons/bi";

function TertiaryFeatures() {
  return (
    <section className="bg-white w-screen py-5 px-5 md:px-32 font-manrope text-white">
      <div className="relative overflow-hidden md:h-[50vh] w-full bg-black rounded-2xl">
        <div className="flex flex-col md:flex-row h-full  items-stretch justify-center w-full">
          <div className="h-full md:w-2/3 flex flex-col space-y-8 items-start justify-center px-5 md:px-20 py-10">
            <div className="relative bg-white rounded-full p-5 w-fit">
              <div className="inset-0 mx-auto my-auto absolute top-1">
                <BiSolidCreditCardFront className="inset-0 mx-auto my-auto text-black absolute  "/>

                <BiSolidCreditCardAlt className="inset-0 mx-auto my-auto text-black absolute bottom-2 left-2"/>
              </div>
            </div>
            <h1 className="font-semibold text-3xl max-w-md">
              Créez des cartes bancaires pour vos achats
            </h1>
            <p className="max-w-lg">
              Spend worldwide with your brand new virtual or physical Mastercard
              debit card.
            </p>
            <div className="bg-white text-black font-semibold flex items-center space-x-2 py-2 px-8 rounded-lg">
              <p>Télécharger</p>
              <FaArrowRight/>
            </div>
          </div>
          <div className="h-full md:w-1/3 px-5 hidden md:flex  md:translate-y-0">
            <img
                className="h-3/4 w-full object-contain self-end"
                loading="lazy"
                alt=""
                src="/frame-427319343-1-1@2x.png"
            />
          </div>
          <div className="h-full md:w-1/3 px-5 flex md:hidden md:translate-y-0">
            <img
                className="h-3/4 w-full object-contain self-end"
                loading="lazy"
                alt="frame-44"
                src="/frame-427319344.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default TertiaryFeatures;
