import { IoLogoAppleAppstore, IoLogoGooglePlaystore } from "react-icons/io5";

function HeroSection() {
  return (
    <section className=" relative bg-black font-sora overflow-hidden">
      <div className="w-full h-full flex flex-col items-center">
        <img
          className="object-cover absolute top-52 md:left-52 md:-top-10 h-[700px] md:h-[180vh] md:w-auto"
          alt="abstract"
          src="/3d-abstract3--6@2x.png"
        />

        <div className="z-10 flex flex-col items-center">
          <h1 className="my-20 px-80 text-white text-center text-6xl">
            Welcome to the future of money
          </h1>

{/*          <div
              className="mb-32 scale-90 md:scale-100 0 h-[250px] w-[400px] rounded-3xl p-px bg-gradient-to-br from-gray-200 to-transparent">
            <div className="flex flex-col h-full backdrop-blur-md bg-black/30 p-10 rounded-3xl justify-center">
              <div className="flex flex-col space-y-12 items-center justify-center">


                <div className="w-full flex items-center justify-between">
                  <img
                      className="h-7 w-auto"
                      loading="lazy"
                      alt=""
                      src="/group-1.svg"
                  />
                  <p className="text-white text-lg self-end">
                    0123 4567 8901 2345
                  </p>
                </div>

                <p className="text-white text-lg self-end">01 / 25</p>
              </div>
            </div>
          </div>*/}

          <div className="mb-32 h-[250px] w-auto px-5 flex ">
            <img
                className="h-full w-auto  "
                loading="lazy"
                alt=""
                src="/visa-gloss.png"
            />
          </div>

          <div className="mb-32 flex flex-col md:flex-row md:space-x-5 space-y-5 md:space-y-0 items-center ">
            <div className="flex bg-white rounded-full  py-2 px-5 text-center items-center text-sm">
              <IoLogoAppleAppstore className="h-8 w-8 text-black "/>
              <div className="w-32">Disponible sur App Store</div>
            </div>
            <div className="flex bg-white rounded-full  py-2 px-5 text-center items-center text-sm">
              <IoLogoGooglePlaystore className="h-8 w-8 text-black "/>
              <div className="w-32">Disponible sur Play Store</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
