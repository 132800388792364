import { Link } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { SquareMenu } from "lucide-react";

function MobileNavLink({
  href,
  children,
    newPage = false
}: {
  href: string;
  children: React.ReactNode;
  newPage?: boolean
}) {
  return (
    <Popover.Button as="a" href={href} target={newPage ? "_blank" : "_self"} rel="noopener noreferrer" className="block w-full p-2 uppercase">
      {children}
    </Popover.Button>
  );
}
function MobileNav() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center ui-not-focus-visible:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <SquareMenu className="w-56 h-56" />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="w-1/2 absolute inset-x-0 left-[40%] mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink href="#primary-features">Nos produits</MobileNavLink>
            <MobileNavLink href="https://cashlesshq.notion.site/Informations-G-n-rales-f54a4423fd414a46bc3ec707b3785d4c" newPage={true}>À propos</MobileNavLink>
            {/*<MobileNavLink href="/">Blog</MobileNavLink>*/}
            <MobileNavLink href="https://cashlesshq.notion.site/FAQ-Produit-c3ebce9c28d0465ab65f013ed9b61b68" newPage={true}>Faq</MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

function Header() {
  return (
    <header className="relative z-20 bg-black flex flex-row justify-between text-white px-5 md:px-10 py-8 w-full">
      <img className="w-28  relative" loading="lazy" alt="" src="/vector.svg" />
      <div className="flex-row space-x-5 justify-end w-1/3 hidden md:flex">
        <div className="uppercase ">
          <a href="#primary-features" >Nos produits</a>
        </div>
        <div className="uppercase ">
          <Link to="https://cashlesshq.notion.site/Informations-G-n-rales-f54a4423fd414a46bc3ec707b3785d4c" target="_blank" rel="noopener noreferrer"> À propos</Link>
        </div>
{/*        <div className="uppercase ">
          <Link to="/">Blog</Link>
        </div>*/}
        <div className="uppercase ">
          <Link to="https://cashlesshq.notion.site/FAQ-Produit-c3ebce9c28d0465ab65f013ed9b61b68" target="_blank" rel="noopener noreferrer">Faq</Link>
        </div>
      </div>

      <div className="flex md:hidden">
        <MobileNav />
      </div>
    </header>
  );
}

export default Header;
