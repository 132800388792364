import { FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

function Footer() {
  const footerNavigation: {
    solutions: { name: string; href: string, new?: boolean }[];
    location: { name: string; href: string }[];
    contact: { name: string; href: string }[];
    socials: { name: string; href: string; icon: any }[];
  } = {
    solutions: [
      { name: "À propos", href: "#primary-features" },
      { name: "Conditions générales d'utilisation", href: "https://cashlesshq.notion.site/Conditions-g-n-rales-d-utilisation-0e37146cdc2c486eaa0187e06849f58e?pvs=4", new: true },
      { name: "Politiques de confidentialité ", href: "https://cashlesshq.notion.site/Politiques-de-confidentialit-86224092a64845dda5bec338923cbd29?pvs=4", new: true },
      { name: "FAQ", href: "https://cashlesshq.notion.site/FAQ-Produit-c3ebce9c28d0465ab65f013ed9b61b68", new: true },
    ],
    location: [],
    contact: [
      {
        name: "contact@cashlesshq.com",
        href: "mailto: contact@cashlesshq.com",
      },
    ],
    socials: [
      {
        name: "Twitter",
        href: "https://x.com/cashless_africa?s=21&t=el_Kg0doIia1ufIsU4TZxA",
        icon: BsTwitterX,
      },
      {
        name: "Instagram",
        href: "https://www.instagram.com/cashless.africa?igsh=NGlqZnNhZXppMGdu",
        icon: FaInstagram,
      },
    ],
  };

  return (
    <div className="bg-black">
      <div className="mx-auto  max-w-7xl px-6 lg:px-8 font-manrope">
        <footer
          aria-labelledby="footer-heading"
          className="relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32"
        >
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="flex flex-col items-start space-y-10">
              <img className="h-7" src="/vector.svg" alt="Company name" />
              <h1 className="text-gray-200">CASHLESS © 2024</h1>
            </div>

            <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-lg font-semibold leading-6 text-white uppercase">
                    Liens utiles
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          target={item.new ? "_blank" : "_self"}
                          rel="noopener noreferrer"
                          className="text-base leading-6 text-white hover:text-gray-200"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-10 md:mt-0">
                  <div className="flex flex-col space-y-5">
                    <div>
                      <h3 className="text-lg font-semibold leading-6 text-white uppercase">
                        Contact
                      </h3>
                      <ul className="mt-6 space-y-4">
                        {footerNavigation.contact.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              className="text-base leading-6 text-white hover:text-gray-200"
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold leading-6 text-white uppercase">
                        Socials
                      </h3>
                      <ul className="mt-6 space-y-4">
                        {footerNavigation.socials.map((item) => (
                          <li key={item.name}>
                            <a
                              href={item.href}
                              target="_blank"
                              rel="noreferrer"
                              className="text-base leading-6 text-white hover:text-gray-200 flex items-center space-x-3"
                            >
                              <div className="p-1 bg-white text-black rounded-full">
                                <item.icon />
                              </div>
                              <span>{item.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-lg font-semibold leading-6 text-white uppercase">
                    Location
                  </h3>
                  <ul className="mt-6 space-y-4">
                    {footerNavigation.location.length > 0 &&
                      footerNavigation.location.map((item) => (
                        <li key={item.name}>
                          <a
                            href={item.href}
                            className="text-base leading-6 text-white hover:text-gray-200"
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
