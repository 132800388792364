function Partners() {
  return (
    <section className="mx-auto max-w-7xl px-6 lg:px-8 font-manrope ">
      <div className="mt-16  flex justify-center">
        <span className="font-semibold text-3xl">Nos partenaires</span>
      </div>
      <div className="my-16 grid grid-cols-4 justify-items-center gap-y-5 md:gap-y-0 ">
        <img
          className="col-span-2 h-16 w-auto object-contain lg:col-span-1"
          src="/codebar-logo.png"
          alt="Codebar"
        />
        <img
          className="col-span-2 h-16 w-auto object-contain lg:col-span-1"
          src="/mtn-logo.png"
          alt="Mtn"
        />
        <img
          className="col-span-2 h-16 w-auto object-contain lg:col-span-1"
          src="/moov-logo.png"
          alt="Moov"
        />
        <img
          className="col-span-2 h-16 w-auto object-contain lg:col-span-1 "
          src="/celtis-logo.png"
          alt="Celtis"
        />
      </div>
    </section>
  );
}

export default Partners;
