function PrimaryFeatures() {
  const features = [
    {
      image: "/credit-cards.png",
      imageClassName: "h-72 w-full object-cover -translate-y-5",
      title: "Carte bancaire",
      subtitle:
        "Créez des cartes virtuelles en plusieurs devises pour vos paiements en ligne.",
    },
    {
      image: "/bank.png",
      imageClassName: "h-2/3 w-auto object-contain",
      title: "Bank as a service",
      subtitle:
        "Liez et gérez vos différents comptes bancaires en seul endroit.",
    },
    {
      image: "/wallet.png",
      imageClassName: "h-full w-auto object-contain",
      title: "Paiement Mobile",
      subtitle:
        "Envoyez & Demandez du cash à vos proches de façon instantanée.",
    },
  ];

  return (
    <section
        id="primary-features"
      className="font-sora bg-white flex flex-col md:flex-row  items-center justify-center  py-28 space-y-8
      md:space-x-5 md:space-y-0"
    >
      {features.map((feature, index) => (
        <div key={index} className="flex flex-col items-center">
          <div
            key={index}
            className="h-64 w-[80vw]  md:w-[28vw] bg-gray-200 rounded-2xl flex flex-col justify-center"
          >
            <img
              className={feature.imageClassName}
              loading="lazy"
              alt=""
              src={feature.image}
            />
          </div>

          <div className="flex flex-col items-center justify-center w-80 mt-5 space-y-3 text-center">
            <p className="font-bold text-4xl">{feature.title}</p>
            <p className="font-normal px-10">{feature.subtitle}</p>
          </div>
        </div>
      ))}
    </section>
  );
}

export default PrimaryFeatures;
