import { BsBank2 } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa6";

function SecondaryFeatures() {
  return (
    <section id="secondary-features" className="bg-white w-screen py-5 px-5 md:px-32 font-manrope">
      <div className="md:h-[50vh] w-full bg-gray-200 rounded-2xl">
        <div className="flex flex-col md:flex-row h-full w-full">
          <div className="h-full md:w-2/3 flex flex-col space-y-8 items-start justify-center px-5 md:px-20 py-10">
            <div className="bg-white rounded-full p-5 w-fit">
              <BsBank2 className="text-black"/>
            </div>
            <h1 className="font-semibold text-3xl max-w-md">
              Vos comptes bancaires gérés en un seul endroit
            </h1>
            <p className="max-w-lg">
              Liez votre compte bancaire depuis l’appli Cashless, déposez et
              retirez de l’argent instantanément depuis votre compte bancaire.
              Obtenez gratuitement un compte bancaire à la création du compte
              Cashless pour recevoir des virements.
            </p>
            <div className="bg-white font-semibold flex items-center space-x-2 py-2 px-8 rounded-lg">
              <p>Lier sa banque</p>
              <FaArrowRight/>
            </div>
          </div>
          <div className="h-full md:w-1/3 px-5 hidden md:flex">
            <img
                className="h-full w-full "
                loading="lazy"
                alt=""
                src="/frame-427319342@2x.png"
            />
          </div>
          <div className="h-full md:w-1/3 px-5 flex md:hidden">
            <img
                className="h-full w-full "
                loading="lazy"
                alt=""
                src="/frame-427319345.png"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default SecondaryFeatures;
