import { IoLogoAppleAppstore, IoLogoGooglePlaystore } from "react-icons/io5";

function CallToAction() {
  return (
    <section className="  w-screen py-5 px-5 md:px-32 font-manrope ">
      <div className="relative bg-black h-full w-full rounded-2xl overflow-hidden">
        <img className="object-cover h-96 w-full" src="/figures.png" alt="" />
        <div className=" absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className=" text-center flex flex-col">
            <h2 className="font-display text-3xl tracking-tight text-white sm:text-4xl">
              Rejoignez l’expérience Cashless dès aujourd’hui !
            </h2>
            <div className="mt-12 flex flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5 items-center justify-center">
              <div className=" flex bg-white rounded-full  py-2 px-3 text-center items-center text-sm space-x-1">
                <IoLogoAppleAppstore className="h-8 w-8 text-black " />
                <div className="w-28">Disponible sur App Store</div>
              </div>
              <div className="flex bg-white rounded-full  py-2 px-3 text-center items-center text-sm space-x-1">
                <IoLogoGooglePlaystore className="h-8 w-8 text-black " />
                <div className="w-28">Disponible sur Play Store</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CallToAction;
